module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"gatsbyRemarkPlugins":[{"resolve":"/opt/build/repo/node_modules/gatsby-remark-table-of-contents","id":"c8328ec3-7e7e-5a4f-b93b-79da737b6c38","name":"gatsby-remark-table-of-contents","version":"2.0.0","modulePath":"/opt/build/repo/node_modules/gatsby-remark-table-of-contents/dist/gatsby-remark-table-of-contents.cjs.js","pluginOptions":{"plugins":[],"exclude":["Table of Contents"],"tight":false,"fromHeading":1,"toHeading":3},"nodeAPIs":[],"browserAPIs":[],"ssrAPIs":[]},{"resolve":"/opt/build/repo/node_modules/gatsby-remark-autolink-headers","id":"84411983-3572-54e6-8782-dedc5abaf1a7","name":"gatsby-remark-autolink-headers","version":"5.9.0","modulePath":"/opt/build/repo/node_modules/gatsby-remark-autolink-headers/index.js","pluginOptions":{"plugins":[]},"nodeAPIs":["pluginOptionsSchema"],"browserAPIs":["onInitialClientRender","shouldUpdateScroll"],"ssrAPIs":["onRenderBody"]},{"resolve":"/opt/build/repo/node_modules/gatsby-remark-relative-images","id":"46bd1896-257f-5076-b73b-7e4836aab9a7","name":"gatsby-remark-relative-images","version":"2.0.2","modulePath":"/opt/build/repo/node_modules/gatsby-remark-relative-images/dist/index.js","module":{"defaultPluginOptions":{"staticFolderName":"static","include":[],"exclude":[]}},"pluginOptions":{"plugins":[]},"nodeAPIs":["onCreateNode"],"browserAPIs":[],"ssrAPIs":[]},{"resolve":"/opt/build/repo/node_modules/gatsby-remark-images","id":"bd1c5994-c32d-5d25-8a64-d2e1174881e1","name":"gatsby-remark-images","version":"6.9.1","modulePath":"/opt/build/repo/node_modules/gatsby-remark-images/index.js","pluginOptions":{"plugins":[],"maxWidth":900,"maxHeight":500,"disableBgImageOnAlpha":true,"linkImagesToOriginal":false},"nodeAPIs":["pluginOptionsSchema"],"browserAPIs":["onRouteUpdate"],"ssrAPIs":[]}],"extensions":[".mdx"],"defaultLayouts":{},"lessBabel":false,"remarkPlugins":[],"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/opt/build/repo","commonmark":false},
    },{
      plugin: require('../node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":900,"maxHeight":500,"disableBgImageOnAlpha":true,"linkImagesToOriginal":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
